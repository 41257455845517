






import Vue from 'vue';
import Component from 'vue-class-component';
import ChoseMediaCenter from '@/pages/Main/ChoseMediaCenter.section.vue';
@Component({
  components: {
    ChoseMediaCenter
  }
})
export default class SelectMediaCenter extends Vue {}
