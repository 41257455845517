























import Vue from 'vue';
import Component from 'vue-class-component';
import MediaCenterForm from '@/components/Form/MediaCenter.form.vue';
import MediaCentersModal from '@/components/Layout/Footer/MediaCentersModal.vue';

@Component({
  components: {
    MediaCenterForm,
    MediaCentersModal
  }
})
export default class ChoseMediaCenter extends Vue {}
